<template>
    <div :class="[sizeClasses[size], 'mx-auto w-full px-4 sm:px-6 lg:px-8']">
        <slot />
    </div>
</template>

<script setup>
const props = defineProps({
    size: {
        type: String,
        default: 'xl',
        validator: (value) => ['2xs', 'xs', 'sm', 'md', 'lg', 'xl', '2xl', 'full'].indexOf(value) > -1,
    },
});

const sizeClasses = {
    '2xs': 'max-w-sm',
    xs: 'max-w-md',
    sm: 'max-w-screen-sm',
    md: 'max-w-screen-md',
    lg: 'max-w-screen-lg',
    xl: 'max-w-screen-xl',
    '2xl': 'max-w-screen-2xl',
    full: 'max-w-full',
};
</script>
